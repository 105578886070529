import React, {useState, useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
//components
import Notifications from "../../components/notifications/notifications";
import Notification from "../../components/side_notification/side_notification";
import Btn from "../../components/buttons/standard/btn";
//utils
import {disableBodyScroll, postReqOptBuilder} from "../../utils/main_utils";
//styles
import "./navbar.css";
//assets
import paraIcon from "../../assets/icons/logo-icon.png";
import logoPlaceholder from "../../assets/placeholders/logo_placeholder.png";
import bell from "../../assets/icons/Bell.svg";
import { SimpleDropdownContainer } from "../../components/dropdowns/simple_dropdown/simple_dropdown";

export default function Navbar(props) {
    const [notificationsDD, setNotificationsDD] = useState(false);
    const [activeDD, setActiveDD] = useState(false);
    const [companyLogo, setCompanyLogo] = useState(logoPlaceholder);
    const [getNewNotifications, setNewNotifications] = useState(false)
    const [notifState, setNotifState] = useState(null);
    const [currentNotifAmount, setCurrentNotifAmount] = useState(0);
    const history = useHistory();
    
    useEffect(()=> {
        fetch(props.para_be + '/media/get_company_logo', {credentials: "include"})
        .then(response => response.json())
        .then(response => (response.status === 200 && response.result && response.result.length > 0) ? setCompanyLogo(response.result) : null)
        .catch(error => {
            console.log(error);
        });
         //get amount of notifications.
        fetch(props.para_be + '/notifications/unread_notif_amount', {credentials: "include"})
        .then(response => response.json())
        .then(response => response.status === 200 ? setCurrentNotifAmount(response.result) : setNotifState({msg: "Failed to fetch notifications", type: "error"}))
        .catch(error => {
            console.log(error);
            setNotifState({msg: "Failed to fetch notifications", type: "error"});
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bodyClickHandler = () => {
        if (activeDD) {
            setActiveDD(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener('click', bodyClickHandler);
        return () => document.body.removeEventListener('click', bodyClickHandler);
    }, [activeDD]);

    //get new notifications to update counter
    useEffect(()=> {
        setTimeout(() => {
            fetch(props.para_be + '/notifications/get_new_notifs', {credentials: "include"})
            .then(response => response.json())
            .then(response => (response.status === 200 && response.result !== 0) ? setCurrentNotifAmount(currentNotifAmount + response.result) : null)
            .catch(error => {
                console.log(error);
            });
            setNewNotifications(!getNewNotifications);
        }, 20000)
    }, [getNewNotifications])
    
    const handleCloseNotifications = () => {
        setNotificationsDD(!notificationsDD);
    };

    const handleNotifOutsideClick = (e) => {
        if (!document.querySelector(".notif-container").contains(e.target)) handleCloseNotifications();
    }

    useEffect(() => {
        if (notificationsDD) document.body.addEventListener('click', handleNotifOutsideClick);
        else document.body.removeEventListener('click', handleNotifOutsideClick);
        
        if (window.innerWidth <= 750) disableBodyScroll(notificationsDD); //only relevant for mobile screens

        return () => {document.body.removeEventListener('click', handleNotifOutsideClick)};
    }, [notificationsDD]);

    const handleUpdateNotifAmount = (amount) => {
        console.log("New notification amount:", amount);
        setCurrentNotifAmount(amount);
    };
    
    const handleCloseNotif = () => setNotifState(null);

    const handleSignOut = () => {
        return fetch(props.para_be + '/auth/logout', postReqOptBuilder(null))
        .then(response => {
            if (response.status === 200) {
                props.handleSignOut();
                history.push("/");
            } else {
                setNotifState({text: "Logout Failed", type: "error"});
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState({text: "Logout Failed", type: "error"});
        });
    }

    return (
        <section className="navbar">
            <div className="logo">
                <a href={props.base_url + "/management"}><img src={paraIcon} alt="logo"/></a>
            </div>
            <div className="right-side-icons">
                <div className="notification-container">
                    <div>
                        <img src={bell} alt ="notifications" onClick={()=>setNotificationsDD(!notificationsDD)}/>
                        {notificationsDD ?
                            <Notifications
                                para_be={props.para_be}
                                base_url={props.base_url}
                                closeFunc={handleCloseNotifications}
                                handleUpdateNotifAmount={handleUpdateNotifAmount}/> : ""
                        }
                    </div>
                    <div className={"notification-number" + (currentNotifAmount === 0 ? " d-none" : "")}>{currentNotifAmount}</div>
                </div>
                {/* <div className="company-logo">
                    <Link to={{pathname: "/settings", state: {prevLocation: window.location.pathname}}}><img src={companyLogo} alt ="logo"/></Link>
                </div>

                <Btn
                    text="Sign Out"
                    type="primary"
                    onclick={handleSignOut}/> */}

                <SimpleDropdownContainer
                    extraClasses="company-logo"
                    extraClassesIn="abs-right-0"
                    showDropdown={activeDD}
                    borderedItems={true}
                    items={{
                        'management': {'present': 'Home Screen', 'extraClasses': 'unselectable', 'disabled': false, 'onclick': (k, e) => { window.location.href = "/management"; }},
                        'settings': {'present': 'Settings', 'extraClasses': 'unselectable', 'disabled': false, 'onclick': (k, e) => { window.location.href = "/settings"; }},
                        'scan_submissions': {'present': 'Scan Submissions', 'extraClasses': 'unselectable', 'disabled': false, 'onclick': (k, e) => { window.location.href = "/scan_submissions"; }},
                        'scans_dashboard': {'present': 'Scans Dashboard', 'extraClasses': 'unselectable', 'disabled': false, 'onclick': (k, e) => { window.location.href = "/scans_dashboard"; }},
                        'sign_out': {'present': 'Sign Out', 'extraClasses': 'unselectable', 'disabled': false, 'onclick': handleSignOut},
                    }}>
                    <div className="company-logo-internal clickable" onClick={(e) => { setActiveDD(!activeDD); e.stopPropagation(); }}>
                        <img src={companyLogo} alt="logo"/>
                    </div>
                </SimpleDropdownContainer>
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </section>
    )
}