import React, {useState,useEffect} from "react";
import { Link } from "react-router-dom";
//style
import "./notifications.css";
//components
import CloseBtn from "../buttons/close_btn/close_btn";
import EmptyState from "../emptyState/empty_state";
import MainLoader from "../loaders/main_loader/main_loader";
import SideNotification from "../side_notification/side_notification";
import ChecklistItem from "../../containers/review_page/checklist_item/checklist_item";
//utils
import { daysAgo } from "../../utils/date_utils";
import { postReqOptBuilder } from "../../utils/main_utils";
import { Notifications as N } from "../../utils/enums_utils";
//assets
import newReport from "../../assets/notifications/new-report.svg";
import comment from "../../assets/notifications/comment.svg";
import alert from "../../assets/notifications/alert.svg";
import deleteIcon from "../../assets/notifications/delete.svg";
import MasterPopup from "../popups/main_popup";

export default function Notifications (props) {
    const [notificationsList, setNotificationsList] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [notifState, setNotifState] = useState(false);


    useEffect(()=> {
        fetch(props.para_be + '/notifications/get_all', {credentials: "include"})
        .then(response => response.json())
        .then(response => {
                if(response.status === 200) {
                    if(response.result && response.result.length === 0) {
                        //handle empty state
                        setNotificationsList(null);
                    } else {
                        setNotificationsList(response.result);
                        setLoading(false);
                    }
                } else {
                    setNotifState({text:response.msg, type:"error"});
                    setNotificationsList(null);
                }
                setLoading(false);
        })
        .catch(error => {
            setNotifState({text:"Error loading notifications", type:"error"});
            setLoading(false);
            setNotificationsList(null);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDeleteNotification = (e,id) => {
        e.stopPropagation();
        e.preventDefault();
        fetch(props.para_be + '/notifications/delete', postReqOptBuilder({"nid": id}))
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                //Remove deleted notification from the list
                let newList = notificationsList.splice(0);
                for (let i=0; i<newList.length; i++) {
                    if (newList[i][0] === id) {
                        newList.splice(i, 1);
                        setNotificationsList(newList);
                        props.handleUpdateNotifAmount(newList.filter((item) => item[7] === 0).length);
                        break;
                    }
                }
                setNotifState({text: "Deleted Successfully", type: "success"});
            } else {
                setNotifState({text: response.msg, type: "error"});
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState({text:"Delete failed", type:"error"});
        });
    }

    const handleReadNotification = (id=undefined) => {
        console.log("hello");
        window.event.stopPropagation();
        changeNotifStatus(id, 1);
    };

    const changeNotifStatus = (id, newStatus) => {
        fetch(props.para_be + '/notifications/read', postReqOptBuilder({"nid": id, "readValue": newStatus}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                const newList = notificationsList.splice(0);
                for (let i=0; i<newList.length; i++) {
                    if (id === undefined || newList[i][0] === id) {
                        newList[i][7] = newStatus;
                    }
                }
                props.handleUpdateNotifAmount(id ? newList.filter((item) => item[7] === 0).length : 0);
                setNotificationsList(newList);
            } else {
                setNotifState({text: response.msg, type: "error"});
            }
        })
        .catch(error => {
            setNotifState({text: "Failed changing notification status", type: "error"});
        });
    };

    const handleRelation = (relation) => {
        fetch(props.para_be + "/notifications/display_notif", postReqOptBuilder({'relation': relation}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                console.log(response);
                const display_notif = response.result;
                console.log("display notif:", display_notif);
                if (display_notif['relation'] === N.N_NEW_COMMENT) {
                    if (window.location.pathname !== display_notif['path']) {
                        window.location.assign(props.base_url + display_notif['path'] + `#ci_id=${display_notif['ci_id']}&comment_id=${display_notif['comment_id']}`);
                    } else {
                        window.location.replace(props.base_url + display_notif['path'] + `#ci_id=${display_notif['ci_id']}&comment_id=${display_notif['comment_id']}`);
                    }
                } else if (display_notif['relation'] === N.N_NEW_UNIT) {
                    if (window.location.pathname !== display_notif['path']) {
                        window.location.assign(props.base_url + display_notif['path'] + `#subject=${display_notif['subject']}`);
                    } else {
                        window.location.replace(props.base_url + display_notif['path'] + `#subject=${display_notif['subject']}`);
                    }
                } else if (display_notif['relation'] === N.N_NEW_REPORT) {
                    if (window.location.pathname !== display_notif['path']) {
                        window.location.replace(props.base_url + display_notif['path']);
                    }
                }
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState({text: 'Failed to display notification', type: 'error'});
        })
    };

    const iconType = (notifType) => {
        let iconUrl = null;
        let title = null;

        switch (notifType) {
            case "new_rep":
                iconUrl = newReport;
                title = "New Report";
                break;
            case "new_com":
                iconUrl = comment;
                title = "New Comment";
                break;
            case "miss_base":
                iconUrl = alert;
                title = "Missing Baseline Scan";
                break
            case "completed_tasks":
                iconUrl = newReport;
                title = "All tasks have been completed";
                break
            case "user_register":
                iconUrl = newReport;
                title = "New user registered";
                break
            case "new_unit":
                iconUrl = newReport;
                title = "New unit has been added";
                break
            case "task_completed":
                iconUrl = newReport;
                title = "All task have been completed";
                break
            default:
                iconUrl = alert;
        }
        return [iconUrl, title];
    };

    const handleCloseNotif = () => {
        setNotifState(false);
    };

    return (
        <div className="notif-container">
            <div className="h2 blue-headline">Notification</div>
            <div className="see-all text-1" onClick={()=>(handleReadNotification())}>Mark all as read</div>
            <CloseBtn closeFunc={props.closeFunc}/>
            <div className="notif-body flexColumn custom-scrollbar-1">
                {isLoading ? <MainLoader/> :
                    notificationsList ? 
                        notificationsList.map(function (item, index) {
                            /*0-id, 1-owner, 2-subject, 3-content, 4-type, 5-relation, 6-dateline, 7-is_read */
                            const type_data = iconType(item[4]);
                            return (
                                <div className={"notification-item-wrapper" + (item[7] === 0 ? " not-read" : "")}>
                                    {/* <div className={item[7] === 1 ? "" : "not-read"} onClick={()=>handleReadNotification(item[0])}> */}
                                    {/* <Link to={"#"}> */}
                                    <div className="notif-item flexRow">
                                        <div className="read-status" onClick={(e) => changeNotifStatus(item[0], item[7] === 0 ? 1 : 0)}>
                                            <div></div>
                                        </div>
                                        <img className="notif-type-icon" src={type_data[0]} alt="notif-type"/>
                                        <div className="notif-item-body flexColumn" onClick={() => handleRelation(item[5])}>
                                            <div className="notif-timestamp text-5">{daysAgo(item[6])}</div>
                                            <div className="notif-item-header h3">{type_data[1]}</div>
                                            <div className="notif-item-subject text-1">{item[2]}</div>
                                            <p className="notif-item-text text-1">{item[3]}</p>
                                            <img className="notif-delete-icon" src={deleteIcon} alt="notif-delete" onClick={(event)=>handleDeleteNotification(event,item[0])}/>
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            )
                        }) :
                        <EmptyState text="You currently have no notifications" size="md"/>
                }
            </div>

            {notifState ?
                <SideNotification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </div>
    )
}