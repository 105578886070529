import React from "react";
//styles
import "./feedback_tooltip.css"

export default function FeedbackTooltip (props) {
    
    return (
        <div className="tt-body">
            <div className="text-3">{props.text}</div>
        </div>
    )
}