import React, { useState, useEffect } from "react";
//components
import Btn from "../../buttons/standard/btn";
//styles
import "../popups.css";
import "./reject_submission.css";

export default function RejectSubmissionPopup (props) {

    const [rejectionMsg, setRejectionMsg] = useState("");

    return (
        <div className='popup-body' id="confirm-popup">
            <div className="h2 blue-headline">Reject Submission</div>
            <div className="text-1">
                Are you sure you want to reject the scan submission?
                <br/>
                The rejection will be sent to the tenant only if a rejection message is provided.
                <br/>
                Please note, this action cannot be undone.
            </div>
            <div className="simple-input-label wrapper-text">
                <div className="simple-input-label text-1-3">
                    Rejection Message (Optional)
                </div>
                <textarea
                    rows={5}
                    className="email-body"
                    name="body"
                    placeholder="Enter rejection message here (Optional)"
                    defaultValue={rejectionMsg}
                    onChange={(e) => { setRejectionMsg(e.target.value); }} />
            </div>
            <div className="btn-container">
                <Btn
                    text="Cancel"
                    type="secondary"
                    onclick={props.onCancel}
                />
                <Btn
                    text="Reject"
                    type="primary"
                    onclick={() => {props.onRejection()}}
                />
            </div>
        </div>
    )
}