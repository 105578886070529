import { useState, useRef } from 'react';
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './App.css';
//containers
import Navbar from "./containers/navbar/navbar";
//pages
import Management from "./containers/management/management";
import Tenancies from './containers/tenancies/tenancies';
import ReviewPage from "./containers/review_page/review_page";
import Login from "./containers/login/login"
import LandingPage from './containers/landing_page/landing_page';
import Settings from './containers/settings/settings';
import LoadingPage from './containers/loading_page/loading_page';
import SignUp from "./containers/sign_up/sign_up.js"
import CreateCompany from './containers/create_company/create_company';
import DemoScan from "./containers/scan_page/demo_scan";
import BaselineScan from "./containers/scan_page/baseline_scan";
import TenantScan from "./containers/scan_page/tenant_scan";
//utils
import { getCookie } from './utils/cookie_monster';
import { isMobile, postReqOptBuilder, urlSearchToObject } from './utils/main_utils';
import ScanManagement from './containers/scan_management/scan_management';
import VideoUploader from './containers/scan_page/upload_baseline';
import AdminScanDashboard from './containers/scans_dashboard/scans_dashboard';
import AdminScanFramesViewer from './containers/scans_dashboard/viewScan';
import ClientScanDashboard from './containers/scans_dashboard/client_scans_dashboard';
import InventoryReview from './containers/scan_management/baseline_inventory_review';
import ClientLanding from './containers/move_out_screen/move_out';
import ScanSubmissions from './containers/scan_submissions/scan_submissions.js';
import Admin_Management from './containers/admin_paraspot/management.js';

function App() {
	const maintenanceState = false;
	const base_url = window.location.protocol + "//" + window.location.hostname;
	const canonicalLink = base_url + window.location.pathname;
	const para_be = base_url + (window.location.port ? ':5000' : "") + "/api";
	const disableNavBarEndPoints = [
        "^/login[/]{0,1}$", "^/signup[/]{0,1}$", "^/about[/]{0,1}$", "^/[/]{0,1}$", "^$", "^/demo-scan[/]{0,1}$", "/checkout_landing_page$",
		"^/demo-scan/t[0-9]+[/]{0,1}$", "^/[^/]+/baseline-scan[/]{0,1}$", "^/checkout/[^/]+/[^/]+[/]{0,1}$", "^/create_company[/]{0,1}$",
		"^/inspection/[^/]+/[^/]+/[^/]+[/]{0,1}$"
    ];
	const endpoints = ["/login", "/signup", "/about", "/management", "/last-report", "/settings"];
	const extendedEndpoints = [
		{v: "/report/", f: (t, s) => t.startsWith(s)},
		{v: "/checkout/", f: (t, s) => t.startsWith(s)},
		{v: "/inspection/", f: (t, s) => t.startsWith(s)},
		{v: "/paraspot/admin/", f: (t, s) => t.startsWith(s)},
		{v: "/baseline-scan", f: (t, s) => t.endsWith(s)},
		{v: "/upload-baseline-scan", f: (t, s) => t.endsWith(s)},
	]
	const [cred, setCred] = useState(undefined); //{cid, uid}
	const token = useRef(null);

  	const location = useLocation();

	const isAllowedMaintenancePass = (__cred) => {
		console.log("maintenanceState:", maintenanceState);
		return maintenanceState ? (__cred && __cred['cid'] === "123456789") : true;
	}
  
	const authentication = async () => {
		if (cred) {
			// console.log("User is already authenticated");
			return;
		}

		if (token.current) {
			// console.log("token has value");
		} else if (location.state && location.state.from === "/login") {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			token.current = location.state.auth;
		} else {
			const cookie_response = await getCookie(para_be, "AuthToken");
			if (cookie_response && cookie_response['status'] === 200) {
				token.current = cookie_response['result'];
			} else if (localStorage.getItem('AuthToken')) {
				token.current = localStorage.getItem('AuthToken');
			} else {
				if (cred !== null) setCred(null);
				return;
			}
		}
		if (token.current) await authenticateToken(token.current);
	};

	const remoteAuthentication = async (token) => {
		return fetch(para_be + '/auth/authenticate', postReqOptBuilder({"auth_key": token})).then( response => response.json());
	};

	const authenticateToken = async (token) => {
		if (token) {
			const auth_response = await remoteAuthentication(token);
			if (auth_response.status === 200) {
				if (!cred || cred['uid'] !== auth_response.result.uid) setCred({"uid": auth_response.result.uid, "cid": auth_response.result.cid});
			} else if (cred !== null) setCred(null);
		}
	};

	const urlListContains = (l, r) => disableNavBarEndPoints.some(i => new RegExp(i).test(r));

	authentication();

	const handleSignOut = () => {
		setCred(undefined);
		localStorage.removeItem('AuthToken');
		token.current=null;
	}

	console.log("Maintenance Allowed:", isAllowedMaintenancePass(cred));

  	return (
		<div className="App">
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"/>
				<link rel="canonical" href={canonicalLink}/>
				<meta property="og:url" content={base_url}/>
			</Helmet>
			{/*show navbar only for authenticated users and NOT about page */}
			{!urlListContains(disableNavBarEndPoints, window.location.pathname) ? <Navbar para_be={para_be} base_url={base_url} handleSignOut={handleSignOut}/> : ""}
			<Switch>
				<Route exact path="/create_company">
					{urlSearchToObject(window.location.search)["admin_token"] === "ParaOnTheSpot2020!" ? 
						<CreateCompany para_be={para_be} uid_cid={cred}/> :
						<Redirect to={cred ? "/management" : "/login"}/>
					}
				</Route>
				<Route exact path="/login">
					{cred ? <Redirect to ="/management"/> : <Login para_be={para_be}/> }
				</Route>
				<Route exact path="/signup">
					{cred ? <Redirect to ="/management"/> : <SignUp para_be={para_be}/> }
				</Route>
				<Route exact path="/about" render={() => <LandingPage para_be={para_be}/>}/>
				<Route exact path="/" render={() => <LandingPage para_be={para_be}/>}/>
				<Route exact path="/:cbe_name/checkout_landing_page" render={(props) => <ClientLanding para_be={para_be} base_url={base_url} {...props}/>}/>
				<Route exact path="/checkout/:cbe_name/:scan_id" render={(props) => <TenantScan para_be={para_be} base_url={base_url} inspection_type={'checkout'} token={token.current} {...props}/>}/>
				<Route exact path="/checkin/:cbe_name/:scan_id" render={(props) => <TenantScan para_be={para_be} base_url={base_url} inspection_type={'checkin'} token={token.current} {...props}/>}/>
				<Route exact path="/inspection/:inspection_type/:cbe_name/:scan_id" render={(props) => <TenantScan para_be={para_be} base_url={base_url} token={token.current} {...props}/>}/>
				{isAllowedMaintenancePass(cred) ?
					<>
						<Route exact path="/demo-scan" render={() => <DemoScan para_be={para_be}/>}/>
						{ encodeURIComponent((new URLSearchParams(window.location.search)).get('token')) === "paraspotAdmin22!" && 
							<>
								<Route exact path="/paraspot/admin/sm/:rid" render={(props) => <ScanManagement para_be={para_be} {...props}/>}/> 
								<Route exact path="/paraspot/admin/dashboard" render={(props) => <AdminScanDashboard para_be={para_be} {...props}/>}/> 
								<Route exact path="/paraspot/admin/scan_viewer/:sid" render={(props) => <AdminScanFramesViewer para_be={para_be} {...props}/>}/> 
							</>
						}
						{/* <Route exact path="/checkout/:cbe_name/:scan_id" render={(props) => <TenantScan para_be={para_be} base_url={base_url} {...props}/>}/> */}
							{/* isMobile() ? <TenantScan para_be={para_be} base_url={base_url} {...props}/> : <LoadingPage/>}/> */}
						{cred ?
							<>
								<Route exact path="/paraspot/admin/clients">
									{urlSearchToObject(window.location.search)["admin_token"] === "ParaOnTheSpot2020!" ?
										<Admin_Management token="ParaOnTheSpot2020!" para_be={para_be}/> :
										<Redirect to="/management"/>
									}
								</Route>
								<Route exact path="/management" render={() => <Management uid_cid={cred} para_be={para_be}/>}/>
								<Route exact path="/tenancies" render={() => <Tenancies uid_cid={cred} para_be={para_be}/>}/>
								<Route exact path="/scan_submissions" render={() => <ScanSubmissions uid_cid={cred} para_be={para_be}/>}/>
								<Route exact path="/scans_dashboard" render={() => <ClientScanDashboard uid_cid={cred} para_be={para_be}/>}/>
								<Route exact path="/report/:rid" render={(props) => <ReviewPage para_be={para_be}  uid_cid={cred} {...props}/>}/>
								<Route exact path="/settings" render={(props) => <Settings para_be={para_be}  uid_cid={cred} {...props}/>}/>
								<Route exact path="/:pid/unit-inventory" render={(props) => <InventoryReview uid_cid={cred} token={token.current} para_be={para_be} base_url={base_url} {...props}/>}/>
								<Route exact path="/scan/:src_id/inventory" render={(props) => <InventoryReview uid_cid={cred} token={token.current} para_be={para_be} base_url={base_url} {...props}/>}/>
								<Route exact path="/:pid/baseline-scan" render={(props) => <BaselineScan uid_cid={cred} token={token.current} para_be={para_be} base_url={base_url} {...props}/>}/>
								<Route exact path="/:pid/upload-baseline-scan" render={(props) => <VideoUploader uid_cid={cred} token={token.current} para_be={para_be} base_url={base_url} {...props}/>}/>
							</> : 
							(cred === undefined ? "" : <Redirect to="/login"/>)
						}
					</> :
					<Route render={() => <Login para_be={para_be}/>}/>
				}
				{/*TODO - Change Login page definition above to the site is under maintenance page */}
			
				{/*TODO - add wrong url handling */}
				{console.log("Had to render default route")}
				{console.log(window.location.pathname)}
				{
					endpoints.includes(window.location.pathname) || extendedEndpoints.map((i) => i.f(window.location.pathname, i.v)) ? <LoadingPage/> :
						<>
							<Route render={() => <Login para_be={para_be}/>}/>
						</>
				}
			</Switch>

		</div>
  	);
}



export default App;