import React, {useState, useEffect} from "react";

//style
import "./simple-loader.css";
//components
import MainLoader from "../../loaders/main_loader/main_loader";
import Btn from "../../buttons/standard/btn";
//containers
//assets
import successImg from "../../../assets/icons/check-circle_v2.svg";

export default function SimpleLoader(props) {
    const {loadedPercentage, msg, loaderSuccess, successCallback} = props;


    return (
        <div className="video-loader">
            {
                loaderSuccess ?
                    (
                        <div className="loading-wrapper">
                            <div className="img-wrapper-rel">
                                <img src={successImg} alt="Success"/>
                            </div>
                            <div className="vl-text">{msg ? msg : <b>Well done!</b>}</div>
                            {successCallback ? <Btn type="primary" extraClasses="load-success-btn" text="Finish" onclick={() => successCallback()}/> : ""}
                            {props.extraElementEnd && props.extraElementEnd}
                        </div>
                    ) :
                    (
                        <div className="loading-wrapper">
                            <div className="simple-loader-container">
                                <div className="simple-loader-text">{loadedPercentage ? `${loadedPercentage}%` : ""}</div>
                                <MainLoader/>
                                {/*<div className="simple-loader"></div>*/}
                            </div>
                            <div className="vl-text">{msg ? msg : "Loading"}</div>
                            {(props.remainingData != null) &&
                                <div className="text-5">Data Left: {props.remainingData}</div>
                            }
                            {/* <div className="help-btn text-1-2" onClick={(e) => props.onHelp()}>Something went wrong? Click Here</div> */}
                            {props.onHelp &&
                                <Btn 
                                    type="secondary" 
                                    extraClasses="help-btn text-0" 
                                    text="Experiencing an Issue? Click Here" 
                                    onclick={(e) => props.onHelp()} />
                            }
                            {props.extraElementEnd && props.extraElementEnd}
                        </div>
                    )
            }
        </div>
    )
}
