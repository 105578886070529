import React, {useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//components
import Btn from '../../components/buttons/standard/btn';
//utils
import { capitalizeString, getClientData, postReqOptBuilder } from '../../utils/main_utils';
//styles
import "./landing_page.css";
//assets
import logo from "../../assets/landing_page/logo.svg"
import contactless from "../../assets/landing_page/contactless.svg";
import accurate from "../../assets/landing_page/accurate.svg";
import efficient from "../../assets/landing_page/efficient.svg";
import dashboard from "../../assets/landing_page/dashboard.jpg";
import purpleScan from "../../assets/landing_page/purple-scan.svg";
import cyanScan from "../../assets/landing_page/cyan-scan.svg";
import orangeScan from "../../assets/landing_page/orange-scan.svg";
import scan from "../../assets/landing_page/scan.svg"
import act from "../../assets/landing_page/act.svg"
import report from "../../assets/landing_page/report.svg"
import vector from "../../assets/landing_page/vector.svg";
import footerLogo from "../../assets/landing_page/footer-logo.svg";
import facebook from "../../assets/landing_page/facebook.svg";
import instagram from "../../assets/landing_page/instagram.svg";
import linkedin from "../../assets/landing_page/linkedin.svg";
import twitter from "../../assets/landing_page/twitter.svg";
import bannerVideo from "../../assets/landing_page/banner_video2.mp4";
import easyWebApp from "../../assets/landing_page/easy-web-app.mp4";
import Notification from '../../components/side_notification/side_notification';
import MasterPopup from '../../components/popups/main_popup';

export default function LandingPage (props) {
    const [clientData, setClientData] = useState(null);
    const [notifState, setNotifState] = useState(false);
    const [popupState, setPopupState] = useState(null);
    const [inputError, setInputError] = useState(null);
    const [errorText, setErrorText] = useState(null);
    const [successMeetingRequest, setSuccessMeetingRequest] = useState(false);
    const scrollToForm = useRef(null);

    useEffect(()=> {
        getClientData().then(data => {
            setClientData(data);
            fetch(props.para_be + '/log_entry', postReqOptBuilder({ip: data ? data[0] : null}, false))
            .then(response => {
                console.log("Entry log status:", response.status);
                console.log("Entry log Response:", response.text());
            });
        });
        if (window.location.hash === "#request_demo") {
            executeScroll();
        }
    // eslint-disable-next-line no-sequences
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setInputError(null);
        let _inputErrors = {};
        let reqBody = ['name', 'email', 'company', 'units'].reduce((item, k) => ({[k]: document.getElementById(k).value, ...item}), {});
        for (let [k, item] of Object.entries(reqBody)) {
            if (!item || item.length === 0) {
                _inputErrors[k] = "Please enter " + capitalizeString(k);
            }
        }
        if (Object.keys(_inputErrors).length > 0) {
            setInputError(_inputErrors);
            return;
        }
        reqBody['ip'] = clientData ? clientData[0] : null;
        reqBody['title'] = document.getElementById("title").value
        reqBody['stay-dur'] = document.getElementById("stay-dur").value
        
        fetch(props.para_be + '/request_demo_meeting', postReqOptBuilder(reqBody, false))
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if (data.status === 200) {
                setSuccessMeetingRequest(true);
                ['name', 'email', 'company', 'title', 'units', 'stay-dur'].map((item) => { document.getElementById(item).value = null; return null;});
                if (errorText) setErrorText(null);
                setNotifState({text: "Request for Demo was sent successfully!", type: "success"});
            } else setErrorText("Oops, something went wrong.\nPlease try again later or contact us at info@paraspot.co");
        })
        .catch(error => {
            console.log(error);
            setErrorText("Oops, something went wrong.\nPlease try again later or contact us at info@paraspot.co");
        });
    };

    const handleClosePopup = (event) =>{
        if (event) event.stopPropagation();
        setPopupState(null);
    };

    const executeScroll = (e) => scrollToForm.current.scrollIntoView();

    return (
        <div className="about-container">
            <Helmet>
                <title>About Paraspot</title>
                <meta name="description" content="Paraspot enables companies in the fields of real estate, hospitality, and logistics, to conduct automatic inspections free of human error, by using computer vision. Moving the physical visits, online."/>
                <meta property="og:title" content="About Paraspot"/>
                <meta property="og:description" content="Paraspot enables companies in the fields of real estate, hospitality, and logistics, to conduct automatic inspections free of human error, by using computer vision. Moving the physical visits, online."/>
                <meta name="robots" content="index, follow"/>
            </Helmet>
            <section className="top-panel">
                <img src={logo} alt="logo"/>
                <div className="about-buttons">
                     {/*btn for 750px and up */}
                    <Btn
                        type="landing"
                        text="REQUEST DEMO"
                        onclick={executeScroll}
                    />
                    <Link to="/login">
                        <div className="sign-in-btn">SIGN IN</div>
                    </Link>
                </div>
            </section>

            <div className="top-banner">
                <div/>
                <video src={bannerVideo} type="video/mp4" autoplay="" loop muted={true}/>
                <div class="lp-header">
                    <h2>AI - powered <br/>property inspection</h2>
                    <h4>Moving the physical visits, online</h4>
                </div>
            </div>

            <section className="features">
                <div>
                    <div className="feature-container">
                        <img src={contactless} alt ="contactless"/>
                        <div className="sh">Contactless</div>
                        <div>Paraspot gives you the ability to conduct automated AI-powered property inspections, without the need for a physical visit.</div>
                    </div>
                    <div className="feature-container">
                        <img src={accurate} alt ="accurate"/>
                        <div className="sh">Accurate</div>
                        <div>We process thousands of real estate images daily. We see things that a human might miss</div>
                    </div>
                    <div className="feature-container">
                        <img src={efficient} alt ="efficient"/>
                        <div className="sh">Efficient</div>
                        <div>Streamline the whole maintenance process. Save cost and time and achieve a quicker turnaround and more satisfied customers</div>
                    </div>
                </div>
                <div>
                    <div className="desc-container">
                        <div className="desc-texts">
                            <div className="desc-headline bh">One easy to use WebApp</div>
                            <div className="desc-text">As a first step your guest or maintenance team is scanning the property with our WebApp. On-screen instructions will guide them through the property and make sure that there are no blind spots</div>
                        </div>
                        <video src={easyWebApp} type="video/mp4" autoplay="" loop muted={true}/>
                    </div>

                    <div className="desc-container">
                        <div className="desc-texts">
                            <div className="desc-headline bh">One smart Dashboard</div>
                            <div className="desc-text">You are able to manage all your units comfortably from here. Get updates on the state of each unit at check in and check out, save time managing the units and provide a flawless service to your guests</div>
                        </div>
                        <img src={dashboard} alt="dashboard"/>
                    </div>
                </div>
            </section>

            <section className="middle-banner">
                <div>Conduct property inspections, from the comfort of your office.</div>
                <img src={purpleScan} id="scan-1" alt="scan-icon"/>
                <img src={cyanScan} id="scan-2" alt="scan-icon"/>
                <img src={orangeScan} id="scan-3" alt="scan-icon"/>
                <img src={purpleScan} id="scan-4" alt="scan-icon"/>
            </section>

            <section className="how-it-works">
                    <div className="hiw-headline bh"> How it works</div>
                    <div className="hiw-text">With the Paraspot Smart Inspection Tool you can provide a contactless and convenient checkout process for your tenant while at the same time keep track on the state of your property. Contactless. Accurate. Efficient</div>

                    <div className="hiw-diagram">
                        <div className="hiw-diagram-item">
                            <img src={scan} alt="scan"/>
                            <div className="hiw-diagram-headline sh">Scan</div>
                            <div className="hiw-diagram-text">Tenant scans the property before checking out using a smartphone and our interactive WebApp camera that leads them through the property</div>
                        </div>
                        <img src={vector} alt="diagram arrow right"/>
                        <div className="hiw-diagram-item">
                            <img src={report} alt="report"/>
                            <div className="hiw-diagram-headline sh">Report</div>
                            <div className="hiw-diagram-text">Our AI checks the scan for any visual changes in the room, and generates a report based on inventory , structural & inventory damages, organization, and a comparison to past state of the room</div>
                        </div>
                        <img src={vector} alt="diagram arrow right"/>
                        <div className="hiw-diagram-item">
                            <img src={act} alt="act"/>
                            <div className="hiw-diagram-headline sh">Act</div>
                            <div className="hiw-diagram-text">Create a to do list for the unit and assign the tasks to your team members, or export the tasks to your work management software.</div>
                        </div>
                    </div>
            </section>

            <section className="contact-form" ref={scrollToForm}>
                <div className="form-headline bh">Set a meeting for a demo</div>
                <div className="form-text">Fill out the form and we will be in touch with you as soon as possible! </div>

                <form>
                    <div className="form-inputs">
                        <div className="form-input-container">
                            <label>Full Name *</label>
                            <input className={inputError?.hasOwnProperty("name") ? "error-input" : ""} id="name" name="name" type="text" placeholder ="Jack Jackford"/>
                        </div>
                        <div className="form-input-container">
                            <label>Company name *</label>
                            <input id="company" name="company" type="text" placeholder ="Jack and Sons"/>
                        </div>
                        <div className="form-input-container">
                            <label>Email *</label>
                            <input className={inputError?.hasOwnProperty("email") ? "error-input" : ""} id="email" name="email" type="email" placeholder="jackford@company.com"/>
                        </div>
                        <div className="form-input-container">
                            <label>Title</label>
                            <input className={inputError?.hasOwnProperty("title") ? "error-input" : ""} id="title" name="title" type="text" placeholder="Operations Manager (Optional)"/>
                        </div>
                        <div className="form-input-container">
                            <label>Approx. Number of Units *</label>
                            <input className={inputError?.hasOwnProperty("units") ? "error-input" : ""} id="units" name="units" type="number" placeholder="300"/>
                        </div>
                        <div className="form-input-container">
                            <label>Average Stay Duration</label>
                            <input className={inputError?.hasOwnProperty("stay-dur") ? "error-input" : ""} id="stay-dur" name="stay-dur" type="text" placeholder="6 Nights (Optional)"/>
                        </div>
                        {/* <div className="input-row">
                            <div className="form-input-container">
                                <label>Full Name *</label>
                                <input className={inputError?.hasOwnProperty("name") ? "error-input" : ""} id="name" name="name" type="text" placeholder ="Jack Jackford"/>
                            </div>
                            <div className="form-input-container">
                                <label>Company name *</label>
                                <input id="company" name="company" type="text" placeholder ="Jack and Sons (Optional)"/>
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="form-input-container">
                                <label>Email *</label>
                                <input className={inputError?.hasOwnProperty("email") ? "error-input" : ""} id="email" name="email" type="email" placeholder="jackford@company.com"/>
                            </div>
                            <div className="form-input-container">
                                <label>Mobile *</label>
                                <input className={inputError?.hasOwnProperty("phone") ? "error-input" : ""} id="phone" name="phone" type="tel" placeholder="+49 30629314201"/>
                            </div>
                        </div> */}
                   </div>
                   {inputError ? <div className="error-input-text">{errorText ? errorText : "Please fill all the required inputs"}</div> : ""}
                   {successMeetingRequest ? <div className="success-request">Request was sent successfully</div> : ""}
                   <Btn
                        type="landing"
                        text="CONTACT"
                        onclick={handleFormSubmit}
                    />
                </form>
            </section>

            <section className="footer">
                <img src={footerLogo} alt="footer logo paraspot"/>
                <div>
                    <div className="footer-disclaimers">
                        <div>Privacy</div>
                        <div>Terms of use</div>
                        <div onClick={() => { 
                                setPopupState([
                                    <>
                                        <div className='h2'>Imprint</div>
                                        <p className='text-1'>
                                            Paraspot GmbH<br/>
                                            Darmstädter Straße 9<br/>
                                            10707 Berlin<br/>
                                            Deutschland<br/>
                                            T +49 30 629314201<br/>
                                            <a href='email:info@paraspot.co'>info@paraspot.co</a><br/>
                                            <a href='www.paraspot.ai'>www.paraspot.ai</a><br/>
                                            <br/>
                                            Sitz der Gesellschaft: Berlin<br/>
                                            Amtsgericht Charlottenburg <br/>
                                            HRB-Nr. 222296 B<br/>
                                            USt-IdNr. DE339797527<br/> 
                                            Geschäftsführer: Yakir Saadia<br/>
                                        </p>
                                    </>, {closeFunc: handleClosePopup}]); 
                            }}>Imprint</div>
                    </div>
                    <div className="footer-rights">All right reserved to Paraspot GmbH 2021</div>
                    <div className="footer-social">
                        <a href="https://www.facebook.com/paraspotAI" target="_blank" rel="noreferrer"><img src={facebook} alt="Facebook"/></a>
                        <a href="https://www.instagram.com/paraspot_ai/" target="_blank" rel="noreferrer"><img src={instagram} alt="Instagram"/></a>
                        <a href="https://www.linkedin.com/company/paraspot-gmbh" target="_blank" rel="noreferrer"><img src={linkedin} alt="LinkedIn"/></a>
                        <a href="https://twitter.com/paraspot" target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter"/></a>
                    </div>
                </div>
            </section>

            {notifState ?
                <Notification
                    closeFunc={() => setNotifState(null)}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
            {popupState ? <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup> : ""}
        </div>
    )
}