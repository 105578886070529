import React, {useState, useEffect} from "react";
//components
import Notification from "../../side_notification/side_notification";
import Btn from "../../buttons/standard/btn";
//utils
import { isMobile, postReqOptBuilder } from "../../../utils/main_utils"
//styles
import "./add_new_unit.css";
//assets
import SimpleInput from "../../inputs/simple_input/input";
import { SingleRadioInput } from "../../radioInput/radioInput";
import ActionableInput from "../../inputs/actionable_input/actionable_input";

export default function AddNewUnit (props) {
    const [baseScanSelection, setBaseScanSelection] = useState(1);
    const [notifState, setNotifState] = useState(false);
    const [inputError, setInputError] = useState(null);
    const isMobileDevice = isMobile();
    // const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(()=> {
        setBaseScanSelection(2); // eslint-disable-next-line no-sequences
    }, []);
    
    const handleApprove = () => {
        const unitInput = document.querySelector("#unit").value;
        if (unitInput.length > 0) {
            setNotifState(false);
            let reqBody = {};
            //validation for email/mobile input
            if (baseScanSelection === 2) {
                const emailOrMobile = document.querySelector("#EM-input").value;
                //TODO - add mobile and email string validation
                if (emailOrMobile.length === 0) {
                    setInputError("emailOrMobile");
                    return;
                } else reqBody['sendLinkTo'] = emailOrMobile;
            } else if (baseScanSelection === 3) reqBody['addLater'] = 1;

            fetch(props.para_be + '/units/new', postReqOptBuilder({"unit": unitInput, "extRef": document.querySelector("#reference").value, ...reqBody}))
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    handleClose(true);
                    if (baseScanSelection === 1) window.location.href = window.location.origin + "/" + response.result.pid + "/baseline-scan";
                } else {
                    setNotifState(response.msg);
                }
            })
            .catch (error => {
                console.log(error);
                setNotifState("An error has occurred, please try again later");
            });
        } else {
            setInputError("unit");
        }
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    const handleClose = (refresh=false) => {
        props.closeFunc(refresh);
    };

    return (
        <div className="new-unit-container">
            <div className="flexColumn">
               <div className="h2 blue-headline">Add New Unit</div>
               <SimpleInput 
                   id={"unit"}
                   name={"unit"}
                   label={"Unit"}
                   placeholder={"Add unit/room number"}
                   type={"text"}
                   inputError={inputError === "unit" ? "Please enter Unit Name" : undefined}
               />
               <SimpleInput 
                   id={"reference"}
                   name={"reference"}
                   label={"Reference"}
                   placeholder={"Add your reference ID"}
                   type={"text"}
               />
               <div className="radio-group-container" id="base-scan">
                    <div className="text-1-3">Add Baseline Scan</div>
                    <SingleRadioInput
                        name={"scan"}
                        label={"Create Baseline Scan"}
                        checked={baseScanSelection === 1}
                        disabled={!isMobileDevice}
                        onclick={() => (isMobileDevice ? setBaseScanSelection(1) : false)}
                    />
                    <SingleRadioInput
                        name={"scan"}
                        label={"Send Link"}
                        checked={baseScanSelection === 2}
                        onclick={() => setBaseScanSelection(2)}
                    >
                        {baseScanSelection === 2 &&
                            <ActionableInput
                                id={"EM-input"}
                                placeholder={"Add your email or mobile number"}
                                inputError={inputError === "emailOrMobile" ? "Please enter email or mobile number" : undefined}
                            />
                        }
                    </SingleRadioInput>
                    <SingleRadioInput
                        name={"scan"}
                        label={"Add Later"}
                        checked={baseScanSelection === 3}
                        onclick={() => setBaseScanSelection(3)}
                    />
                </div>
                <Btn
                    text="Approve"
                    type="primary"
                    onclick = {handleApprove}
                />
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div>
    )
}
