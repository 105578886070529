import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// Styles
import "./tenancies.css";
// Components
import Pagination from "../../components/pagination/pagination";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import EmptyState from "../../components/emptyState/empty_state";
import Notification from "../../components/side_notification/side_notification";
import MasterPopup from "../../components/popups/main_popup";
import Btn from "../../components/buttons/standard/btn";
// Utils
import { dateTimeFormater } from "../../utils/date_utils.js";
// Assets
import searchUp from "../../assets/icons/search-up.svg";
import searchDown from "../../assets/icons/search-down.svg";
import searchDownSelect from "../../assets/icons/search-down-select.svg";
import searchUpSelect from "../../assets/icons/search-up-select.svg";
import dots from "../../assets/icons/dots.svg";
import { SimpleDropdownContainer } from "../../components/dropdowns/simple_dropdown/simple_dropdown";
import AddNewTenancy from "../../components/popups/add_new_tenancy/add_new_tenancy";
// import UpdateTenancy from "../../components/popups/update_tenancy/update_tenancy";
//constants
const itemsPerPage = 20;

export default function Tenancies(props) {
    
    // state based variables
    const [isLoader, setLoader] = useState(true);
    const [allTenancies, setAllTenancies] = useState([]);
    const [currentSort, setCurrentSort] = useState({column: null, direction: null}); //sort direction(true ASC, false DESC)
    const [activeDD, setActiveDD] = useState(null);
    const [maxItems, setMaxItems] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMainEmptyState, setMainEmptyState] = useState(false);
    const [extendedTableItem, setExtendedTableItem] = useState(null);
    const [notifState, setNotifState] = useState(null);
    const [popupState, setPopupState] = useState(null);
    const [refresh, setRefresh] = useState(false);
    
    // Function to fetch tenancies based on optional query parameters
    const fetchTenancies = (queryParams = null) => {
        // Set loader to true indicating the start of the data fetch
        setLoader(true);
        // Construct the URL for the API call
        let url = props.para_be + "/tenancies/search";
        if (queryParams) {
            url = `${url}?${queryParams}`;
        }
        // Make the API call
        fetch(url, {credentials: "include"})
            .then((response) => response.json())
            .then((response) => {
                // Check if the API call was successful
                if (response.status === 200) {
                    // Check if there is data in the response
                    if (
                        response.result &&
                        Object.keys(response.result).length > 0
                    ) {
                        // Set the state with the received data
                        setAllTenancies(response.result.data);
                        setMaxItems(response.result.maxRows);
                        // If the component was in an empty state, update it
                        if (isMainEmptyState) setMainEmptyState(false);
                    } else {
                        // Set empty state if there is no data
                        setMainEmptyState(true);
                    }
                } else {
                    // Handle error response
                    setNotifState({
                        text: response.msg || "Error in fetching data",
                        type: "error",
                    });
                }
            })
            .catch((error) => {
                // Handle API call error
                setMainEmptyState(true);
                setNotifState({ text: "Can't fetch data", type: "error" });
            })
            .finally(() => {
                // Set loader to false regardless of success or failure
                setLoader(false);
            });
    };

    // Effect hook to fetch tenancies when the 'refresh' dependency changes
    useEffect(() => {
        fetchTenancies();
    }, [refresh]);

    const handleSortUnits = (index) => {
        let sortDirection =
            currentSort.column !== index ? false : !currentSort.direction;
        setCurrentPage(1);
        setCurrentSort({ column: index, direction: sortDirection });
    };

    const handleChangePage = (page) => {
        const params = `lowerLimit=${(page - 1) * itemsPerPage}&upperLimit=${
            page * itemsPerPage
        } `;
        fetchTenancies(params);
        setCurrentPage(page);
    };

    useEffect(() => {
        if (activeDD) {
            document.body.addEventListener("click", closeDD);
            return () => {
                document.body.removeEventListener("click", closeDD);
            };
        }
    }, [activeDD]);

    useEffect(() => {
        closeDD();
    }, [popupState]);
    
    const closeDD = () => {
        if (activeDD) setActiveDD(null);
    };
    
    const handleDropDown = (event, t) => {
        event.stopPropagation();
        if (activeDD === t) closeDD();
        else {
            if (activeDD) closeDD();
            setActiveDD(t);
        }
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    };
    
    const handleClosePopup = (refresh = false) => {
        setPopupState(null);
        if (refresh) handleChangePage(currentPage);
    };
    
    const handleOpenNewTenancyPopUp = () => {
        setPopupState([
            <AddNewTenancy
                closeFunc={handleClosePopup}
                para_be={props.para_be}
                refresh={refresh}
                setRefresh={setRefresh}
            />,
            { closeFunc: handleClosePopup },
        ]);
    };
    
    // const handleEditTencies = (item) => {
    //     setPopupState([
    //         <UpdateTenancy
    //             closeFunc={handleClosePopup}
    //             para_be={props.para_be}
    //             updatedItem={item.client_name}
    //             checkin={item.checkin}
    //             checkout={item.checkout}
    //             tenancy_id={item.tenancy_id}
    //             refresh={refresh}
    //             setRefresh={setRefresh}
    //         />,
    //         { closeFunc: handleClosePopup },
    //     ]);
    // };

    return (
        <section className="mgmt-main">
            <Helmet>
                <title>Paraspot | Tenancies</title>
                <meta name="description" content="." />
                <meta property="og:title" content="Paraspot | Tenancies" />
                <meta property="og:description" content="." />
            </Helmet>
            <div className="mgmt-header flexRow">
                <div className="h1">Tenancies</div>
                <div className="mgmt-header-btns flexRow">
                    <Btn
                        text="Add New Tenancies"
                        type="primary"
                        extraClasses="mgmt-add-2"
                        onclick={() => handleOpenNewTenancyPopUp()}
                    />
                </div>
            </div>
            <div className="mgmt-table">
                <div className="top-pg-container">
                    <div>
                        {currentPage < 2 ? 1 : (currentPage - 1) * itemsPerPage + 1}
                        -
                        {itemsPerPage * currentPage >= maxItems ? maxItems : itemsPerPage * currentPage}{" "}
                        of {maxItems} Tenancies
                    </div>
                </div>
                <table cellSpacing="0" cellPadding="0">
                    <thead className="desktop-only">
                        <tr>
                            {Object.entries({
                                    Name: {
                                        sortable: false,
                                        className: "th-name",
                                    },
                                    Unit: {
                                        sortable: true,
                                        className: "th-deposit",
                                    },
                                    "Check In": {
                                        sortable: true,
                                        className: "th-in",
                                    },
                                    "Check Out": {
                                        sortable: true,
                                        className: "th-out",
                                    },
                                    Status: {
                                        sortable: false,
                                        className: "th-status",
                                    },
                                    Action: {
                                        sortable: false,
                                        className: "th-act",
                                    },
                                }).map(([k, v], index) => {
                                    let isCurrentSort =
                                        currentSort.column === index + 1 &&
                                        v["sortable"];
                                    return (
                                        <th className={v["className"] + (isCurrentSort ? " sort-active" : "")}>
                                            <div {...(v["sortable"] ? {onClick: () => handleSortUnits(index + 1)} : {})}>
                                                <span>{k}</span>
                                                {v["sortable"] && (
                                                    <div className="sort-icons flexColumn">
                                                        <img
                                                            src={isCurrentSort && currentSort.direction === true ? searchUpSelect : searchUp}
                                                            alt="arrow-up" />
                                                        <img
                                                            src={isCurrentSort && currentSort.direction === false ? searchDownSelect : searchDown}
                                                            alt="arrow-down" />
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader ? (
                            <MainLoader />
                        ) : isMainEmptyState ? (
                            <EmptyState
                                text={"No data, add new item"}
                                size="lg"
                            />
                        ) : (
                            allTenancies?.map(function (item, index) {
                                // Extracting relevant properties from the API response
                                const [tenancy_id, pid, ext_ref, subject, checkin, checkout, deposit, rent, contact, metadata, client_name] = item;
                                const contactJson = JSON.parse(contact);
                                const {email, 'mobile': phone} = contactJson
                                
                                const item_id = "mgmt_item_" + index;
                                const dateTimeToPrintCheckIn = dateTimeFormater(checkin, "dd/mm/yyyy");
                                const dateTimeToPrintCheckOut = dateTimeFormater(checkout, "dd/mm/yyyy");

                                const outRent = rent && typeof rent === "string" ? JSON.parse(rent).rate : (rent ? rent.rate : "NaN");
                                const outDeposit = deposit && typeof deposit === "string" ? JSON.parse(deposit).rate : (deposit ? deposit.rate : "NaN");

                                const extendedSection = tenancy_id !== extendedTableItem ? "" 
                                    :
                                    <tr className="table-row-extension">
                                        <td colSpan={6}>
                                            <div className="unit-info">
                                                <div className="text-1-3">Tenancy Details</div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Email: {email || "NaN"}
                                                    </div>
                                                </div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Mobile: {phone || "NaN"}
                                                    </div>
                                                </div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Rent: {outRent}
                                                    </div>
                                                </div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Security Deposit: {outDeposit}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>;
                                
                                return (
                                    <>
                                        <tr id={item_id} key={item_id} onClick={() => { 
                                                console.log("[i] Changing table item extension view to:", tenancy_id !== extendedTableItem ? tenancy_id : null); 
                                                setExtendedTableItem(tenancy_id !== extendedTableItem ? tenancy_id : null); 
                                            }}>
                                            <td data-th="name">{client_name}</td>
                                            <td className={currentSort.column === 2 ? "sort-active" : ""} data-th="Unit" title={`(${ext_ref}) ${subject}`}>{`(${ext_ref}) ${subject}`}</td>
                                            <td className={currentSort.column === 3 ? "sort-active" : ""} data-th="Check In">
                                                {dateTimeToPrintCheckIn}
                                            </td>
                                            <td
                                                className={currentSort.column === 4 ? "sort-active" : ""}
                                                data-th="Check Out"
                                                title={dateTimeToPrintCheckOut}
                                            >
                                                {dateTimeToPrintCheckOut}
                                            </td>
                                            <td data-th="Share">
                                                <div className="miss-baseline-label para-label para-l-info">NaN</div>
                                            </td>
                                            <td data-th="Action" className="th-act">
                                                <div className="report-btns">
                                                    <SimpleDropdownContainer
                                                        extraClasses="more-items"
                                                        showDropdown={activeDD === tenancy_id}
                                                        borderedItems={true}
                                                        items={{
                                                            edit_unit: {
                                                                disabled: true,
                                                                present:
                                                                    "Edit Tenancies",
                                                                onclick: () => {
                                                                    // handleEditTencies(
                                                                    //     item
                                                                    // );
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <img
                                                            src={dots}
                                                            alt="more-btn"
                                                            className="more-btn"
                                                            onClick={(e) => handleDropDown(e, tenancy_id)}
                                                        />
                                                    </SimpleDropdownContainer>
                                                </div>
                                            </td>
                                        </tr>
                                        {extendedSection}
                                    </>
                                );
                            })
                        )}
                    </tbody>
                </table>

                {isMainEmptyState ? "" : (
                    <Pagination
                        currentPage={currentPage}
                        maxPages={Math.ceil(maxItems / itemsPerPage)}
                        handleChangePage={(page) => handleChangePage(page)}
                        setNotifState={setNotifState}
                        itemsPerPage={itemsPerPage}
                    />
                )}
            </div>

            {notifState ? (
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}
                />
            ) : ""}
            {popupState ? (
                <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup>
            ) : ""}
        </section>
    );
}