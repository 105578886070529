import { useState, useRef} from "react";
//styles
import "../input.css"
import "./actionable_input.css";
import InputError from "../../errorMessages/inputError/inputError";
//assets
import rightArrow from "../../../assets/icons/arrow-right.svg"

export default function ActionableInput (props) {
    const [value, setValue] = useState(props.value);
    return (
        <div className="actionable-input-container">
            <div className={"actionable-input flexRow" + (props.inputError ? " input-error-2" : "")}>
                <input 
                    id={props.id}
                    name={props.name}
                    type={props.type ? props.type : "text"}
                    className={"text-0 text-no-resp" + (value && value.length > 0 ? " input-used" : "") + (props.extraClasses ? ` ${props.extraClasses}` : "") + (props.inputError ? " input-error-2" : "")} 
                    value={value}
                    placeholder={props.placeholder ? props.placeholder : " "}
                    onChange={(e) => {
                        setValue(e.target.value);
                        if (props.on_change) props.on_change(e);
                    }}
                />
                <div className={"actionable-input-btn unselectable"} onClick={props.onclick}>
                    <img alt="action" src={rightArrow} />
                </div>
            </div>
            {props.inputError ? <InputError text={props.inputError} type="error"/> : ""}
        </div>
    )
}
