import React, {useState, useEffect} from "react";
//components
import Notification from "../../side_notification/side_notification";
//utils
//styles
import "../all_reports/all_reports.css";
import "./search_filter.css";
import { SingleRadioInput } from "../../radioInput/radioInput";
import Btn from "../../buttons/standard/btn";

export default function SearchFilter(props) {
    const [allProperties, setAllProperties] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState(props.currentFilters?.["selected_properties"] || []);
    const [notifState, setNotifState] = useState(null);
    const [showUpC_Range, setShowUpC_Range] = useState(false);
    const [upcVal, setUpcVal] = useState(props.currentFilters?.upcomingCheckouts?.opt || "any");
    const [prVal, setPrVal] = useState(props.currentFilters?.pastReports?.opt || "any");
    const [bsVal, setBsVal] = useState(props.currentFilters?.['baseline-scan']?.opt || "any");
    const [uoVal, setUoVal] = useState(props.currentFilters?.['unit-occupation']?.opt || "any");
    
    useEffect(() => {
        if (props.activePMS) {
            fetch(props.para_be + '/units/get_all_properties', {credentials: "include"})
            .then(response => response.json())
            .then(response => {
                //DATA 0-report id, 1-status, 2-datetime
                if (response.status === 200) {
                    setAllProperties(response.result);
                } else setNotifState(response.msg);
            })
            .catch(error => {
                setNotifState("An error has occurred while fetching all properties, please try again later");
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const applyChanges = () => {
        let newFilters = props.currentFilters;
        let radioGroups = [
            {'group_name': 'past-reports', 'key': 'pastReports', 'range_prefix': 'pr-range-'},
            {'group_name': 'baseline-scan', 'key': 'baseline-scan'},
            ...(props.activePMS ? [
                {'group_name': 'upcoming-checkouts', 'key': 'upcomingCheckouts', 'range_prefix': 'upc-range-'},
                {'group_name': 'unit-occupation', 'key': 'unit-occupation'}
            ] : [])
        ];
        for (let radioGroup of radioGroups) {
            let cfKey = radioGroup['key'];
            let cfRangePrefix = radioGroup?.['range_prefix'];
            let rgValue = document.querySelector("input[name=" + radioGroup['group_name'] + "]:checked")?.value;
            if (props.currentFilters[cfKey].opt !== rgValue || rgValue === "range") {
                let newRgValue = {opt: rgValue};
                if (rgValue === "range") {
                    let rgRangeStart = document.querySelector("input#" + cfRangePrefix + "start").value;
                    let rgRangeEnd = document.querySelector("input#" + cfRangePrefix + "end").value;
                    if (props.currentFilters?.[cfKey]?.value?.[0] !== rgRangeStart) {
                        newRgValue['value'] = [rgRangeStart];
                    } else {
                        newRgValue['value'] = [props.currentFilters?.[cfKey]?.value?.[0]];
                    }
                    if (props.currentFilters?.[cfKey]?.value?.[1] !== rgRangeEnd) {
                        newRgValue['value'].push(rgRangeEnd);
                    } else {
                        newRgValue['value'].push(props.currentFilters?.[cfKey]?.value?.[1]);
                    }
                }
                newFilters[cfKey] = newRgValue;
            }
        }

        newFilters['selected_properties'] = Array.prototype.slice.call(document.querySelectorAll("input[name=selected_properties]:checked")).map( (el) => el.value );

        props.applyChanges(newFilters);
        props.closeFunc(true);
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    return (
        <div className="ar-container sf-container">
            <div>
                <div className="h2 blue-headline">Filter</div>
                <div className="sf-body custom-scrollbar-1">
                    { props.activePMS &&
                        <div className="radio-group-container" id="upcoming-checkouts">
                            <div className="text-1-3">Upcoming Checkouts</div>
                            {
                                [
                                    {'label': "Any", 'value': "any", 'checked': upcVal === "any"},
                                    {'label': "7 Days", 'value': "7d", 'checked': upcVal === "7d"},
                                    {'label': "30 Days", 'value': "30d", 'checked': upcVal === "30d"},
                                    {'label': "This Month", 'value': "this_month", 'checked': upcVal === "this_month"},
                                ].map( (radioItem) => {
                                    return <SingleRadioInput
                                                name={"upcoming-checkouts"}
                                                label={radioItem.label}
                                                value={radioItem.value}
                                                checked={radioItem.checked}
                                                onclick={() => setUpcVal(radioItem.value)}
                                            />
                                })
                            }
                            <SingleRadioInput
                                name={"upcoming-checkouts"}
                                label={"Set Range"}
                                value={"range"}
                                checked={upcVal === "range"}
                                onclick={() => { setUpcVal("range"); setShowUpC_Range(document.querySelector("input[name=upcoming-checkouts]:checked") === "range"); }}
                            >
                                {(upcVal === "range" || showUpC_Range) &&
                                    <div className="range-input flexRow">
                                        <input id="upc-range-start" type="date" className="text-1" value={props.currentFilters?.upcomingCheckouts?.value?.[0]} />
                                        <span>-</span>
                                        <input id="upc-range-end" type="date" className="text-1" value={props.currentFilters?.upcomingCheckouts?.value?.[1]} />
                                    </div>
                                }
                            </SingleRadioInput>
                        </div>
                    }
                    <div className="radio-group-container" id="past-reports">
                        <div className="text-1-3">Past Reports</div>
                        {
                            [
                                {'label': "Any", 'value': "any", 'checked': prVal === "any"},
                                {'label': "24 Hours", 'value': "24h", 'checked': prVal === "24h"},
                                {'label': "7 Days", 'value': "7d", 'checked': prVal === "7d"},
                                {'label': "This Month", 'value': "this_month", 'checked': prVal === "this_month"},
                            ].map( (radioItem) => {
                                return <SingleRadioInput
                                            name={"past-reports"}
                                            label={radioItem.label}
                                            value={radioItem.value}
                                            checked={radioItem.checked}
                                            onclick={() => setPrVal(radioItem.value)}
                                        />
                            })
                        }
                        <SingleRadioInput
                            name={"past-reports"}
                            label={"Set Range"}
                            value={"range"}
                            checked={prVal === "range"}
                            onclick={() => { setPrVal("range"); setShowUpC_Range(document.querySelector("input[name=past-reports]:checked") === "range"); }}
                        >
                            {(prVal === "range" || showUpC_Range) &&
                                <div className="range-input flexRow">
                                    <input id="pr-range-start" type="date" className="text-1" value={props.currentFilters?.pastReports?.value?.[0]} />
                                    <span>-</span>
                                    <input id="pr-range-end" type="date" className="text-1" value={props.currentFilters?.pastReports?.value?.[1]} />
                                </div>
                            }
                        </SingleRadioInput>
                    </div>
                    <div className="radio-group-container" id="baseline-scan">
                        <div className="text-1-3">Baseline Scan</div>
                        {
                            [
                                {'label': "Any", 'value': "any", 'checked': bsVal === "any"},
                                {'label': "Has a baseline", 'value': "has_baseline", 'checked': bsVal === "has_baseline"},
                                {'label': "Missing baseline", 'value': "missing_baseline", 'checked': bsVal === "missing_baseline"}
                            ].map( (radioItem) => {
                                return <SingleRadioInput
                                            name={"baseline-scan"}
                                            label={radioItem.label}
                                            value={radioItem.value}
                                            checked={radioItem.checked}
                                            onclick={() => setBsVal(radioItem.value)}
                                        />
                            })
                        }
                    </div>
                    { props.activePMS &&
                        <>
                            <div className="radio-group-container" id="unit-occupation">
                                <div className="text-1-3">Occupied</div>
                                {
                                    [
                                        {'label': "Any", 'value': "any", 'checked': uoVal === "any"},
                                        {'label': "Occupied Units", 'value': "occupied_units", 'checked': uoVal === "occupied_units"},
                                        {'label': "Unoccupied Units", 'value': "unoccupied_units", 'checked': uoVal === "unoccupied_units"}
                                    ].map( (radioItem) => {
                                        return <SingleRadioInput
                                                    name={"unit-occupation"}
                                                    label={radioItem.label}
                                                    value={radioItem.value}
                                                    checked={radioItem.checked}
                                                    onclick={() => setUoVal(radioItem.value)}
                                                />
                                    })
                                }
                            </div>

                            <div className="checkbox-group-container">
                                <div className="text-1-3">Properties</div>
                                {
                                    (allProperties || []).map( (propertyItem) => {
                                        let propertyItemVal = propertyItem.name.toLowerCase();
                                        return {
                                            label: `${propertyItem.name} (${propertyItem.count})`, 
                                            value: propertyItemVal, 
                                            checked: selectedProperties.includes(propertyItemVal)
                                        }
                                    }).map( (checkboxItem) => {
                                        return <div className="checkbox-container-1 flexRow" onClick={() => {
                                            let newSelectedProperties = Array.prototype.slice.call(selectedProperties);
                                            if (selectedProperties.includes(checkboxItem.value)) {
                                                newSelectedProperties.splice(newSelectedProperties.indexOf(checkboxItem.value), 1);
                                            } else newSelectedProperties.push(checkboxItem.value);
                                            setSelectedProperties(newSelectedProperties);
                                        }}>
                                                    <input type="checkbox" name="selected_properties" class="mul-selection-checkbox" value={checkboxItem.value} checked={checkboxItem.checked}/>
                                                    <div class="text-2">{checkboxItem.label}</div>
                                                </div>
                                    })
                                }
                            </div>
                        </>
                    }
                </div>
                <div className="btns-wrapper flexRow">
                    <Btn 
                        text="Cancel"
                        type="secondary"
                        extraClasses="text-1-3"
                        onclick={() => props.closeFunc()}/>
                    <Btn 
                        text="Apply Filters"
                        type="primary"
                        extraClasses="text-1-3"
                        onclick={() => applyChanges()}/>
                </div>
            </div>

            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div>
    )
}