import React, {useState, useEffect} from "react";
//components
import Notification from "../../side_notification/side_notification";
import Btn from "../../buttons/standard/btn";
//utils
//styles
import "./index.css";
import "../../uploadVideo/index.css";
import { postReqOptBuilder } from "../../../utils/main_utils";
//assets


export default function BulkUpload (props) {
    const [notifState, setNotifState] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [uploadedFile, setUploadedFile] = useState("");
    const [show, setShow] = useState(false); //show the progressbar
    const [loader, setLoader] = useState(false); //show the progressbar
    const [complete, setcomplete] = useState(false); // Indicates whether a process or task is complete

    useEffect(()=> {
    }, []);
    
    const handleUpload = () => {
        setShow(true);
        setLoader(true);
        const formData = new FormData();
        formData.append('csv_file', uploadedFile);
        
        fetch(props.para_be + '/units/bulk_upload', postReqOptBuilder(formData, true, {}, false))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setLoader(false);
                setcomplete(true);
            } else {
                console.log(response);
                setNotifState("An error has occurred, please try again later");
                setLoader(false);
                setShow(false);
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState("An error has occurred, please try again later");
            setLoader(false);
            setShow(false);
        });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);

        const file = e.dataTransfer.files[0];
        setUploadedFile(file);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    const handleClose = (refresh=false) => {
        props.closeFunc(refresh);
    };

    return (
        <div className="bulk-upload-container">
            <div className="flexColumn">
                <div className="h2 blue-headline">Bulk Upload</div>
                <div className="text-1-3">
                    Upload a .xslx/.csv file to create or update in bulk a set of units, tenancies, or both.
                </div>
                <div className="text-2">
                    To upload units it is necessary to have "Unit" and "Reference" columns.
                    <br/>
                    To upload tenancies it is necessary to have "Tenant Full Name", "Move-In", and "Move-Out" columns.
                    <br/>
                    Please note that dates should be submitted in the following format yyyy-mm-dd
                </div>
                <div className="text-2" style={{"margin": "20px 0 10px"}}>Here are the available columns in the table:</div>
                <div className="scrollable-presentation" style={{"margin": "0 0 20px"}}>
                    <table className="fake-presentation-table" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className="text-1-3">Unit</th>
                                <th className="text-1-3">Reference</th>
                                <th className="text-1-3">Tenant Full Name</th>
                                <th className="text-1-3">First Name</th>
                                <th className="text-1-3">Last Name</th>
                                <th className="text-1-3">Salutation</th>
                                <th className="text-1-3">Email</th>
                                <th className="text-1-3">Phone</th>
                                <th className="text-1-3">Move-In</th>
                                <th className="text-1-3">Move-Out</th>
                                <th className="text-1-3">Deposit</th>
                                <th className="text-1-3">Rent</th>
                                <th className="text-1-3">Currency</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={`excel-uploader ${isDragOver ? "drag-over" : ""}`} onDrop={handleDrop}>
                    {uploadedFile ? 
                        <div className="uploaded-item">
                            <div className="text-1-3">{uploadedFile.name}</div>
                        </div>
                        :
                        <label className="upload-box">
                            <p>
                                Drag & drop a .xlsx/.csv file here or click to select a file
                            </p>
                            <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange} />
                        </label>
                    }
                </div>

                {show &&
                    <>
                        {loader && 
                            <div className="flex-row">
                                <span> Processing</span>  
                                <div className="spinner"></div>
                            </div>
                        }
                        {complete &&
                            <h3 className="upload-complete h2" >
                                Upload Complete
                            </h3>
                        }
                    </>
                }

                <Btn
                    text="Upload"
                    type="primary"
                    onclick = {handleUpload}
                />
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div>
    )
}
