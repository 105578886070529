import React, { useEffect } from "react";
// components
import { SimpleDropdownContainer } from "../../../../components/dropdowns/simple_dropdown/simple_dropdown";
// styles
import "./index.css";
// assets
import dots from "../../../../assets/icons/dots.svg";

export default function Employees(props) {
    const { handleOpenPopup, employeesData, activeDD, handleDropDown } = props;
    
    useEffect(() => {
        const handleScroll = () => {
            const comp = document.querySelector(".employees");
            const saveSection = document.querySelector(".save-section.change-occurred");
            if (!comp || !saveSection) return;
            
            const changePoint = comp.getBoundingClientRect().bottom - window.innerHeight;
            if (window.scrollY >= changePoint && saveSection.classList.contains("floating-bar")) {
                saveSection.classList.remove("floating-bar");
            } else if (window.scrollY < changePoint && !saveSection.classList.contains("floating-bar")) {
                saveSection.classList.add("floating-bar");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    
    return (
        <section className="employees">
            <div className="employees-header">
                <div>Employees</div>
                <div onClick={() => handleOpenPopup("emp")}>+ Add Employees</div>
            </div>
            <table className="employees-table ">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>User Type</th>
                        <th>Role Type</th>
                        <th>User Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {employeesData ? 
                        employeesData.map(function ([ userId, userType, firstName, lastName, userRole, status ]) {
                            return (
                                <tr id={userId}>
                                    <td data-th="Name">{firstName + " " + lastName}</td>
                                    <td data-th="User Type">{userType}</td>
                                    <td data-th="Role">{userRole}</td>
                                    <td data-th="Status">{status}</td>
                                    <td>
                                        <SimpleDropdownContainer
                                            showDropdown={activeDD === userId}
                                            borderedItems={true}
                                            items={{
                                                update_user: {
                                                    present: "Update user",
                                                    onclick: (k, e) => handleOpenPopup("edit_user", userId, userType, userRole, status)
                                                },
                                            }}
                                        >
                                            <img
                                                src={dots}
                                                alt="more-btn"
                                                onClick={(e) => handleDropDown(e, userId)}
                                            />
                                        </SimpleDropdownContainer>
                                    </td>
                                </tr>
                            );
                        })
                        : ""
                    }
                </tbody>
            </table>
        </section>
    );
};