import React, {useState, useEffect, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

//styles
import "./management.css";
//components
import UrlGeneratorCell from "../../components/cellComponents/managementUrlGenerator";
import Pagination from "../../components/pagination/pagination";
import Btn from "../../components/buttons/standard/btn";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import EmptyState from "../../components/emptyState/empty_state";
import Notification from "../../components/side_notification/side_notification";
import MasterPopup from "../../components/popups/main_popup";
import UpdateUnitRef from "../../components/popups/update_reference_number/update_unit_ref";
import ConfirmPopup from "../../components/popups/confirmation/confirmation_popup";
import AddNewUnit from "../../components/popups/add_new_unit/add_new_unit";
import AllReports from "../../components/popups/all_reports/all_reports";
import VideoUploader from "../../components/uploadVideo/index"
import { SimpleDropdownContainer, SimpleInputDropdown } from "../../components/dropdowns/simple_dropdown/simple_dropdown";
import ViewBaseline from "../../components/popups/view_baseline/view_baseline";
import SearchFilter from "../../components/popups/search_filter/search_filter";
import AllTenancies from "../../components/all_tenancies";
import BulkUpload from "../../components/popups/bulk_upload/index.js";
//utils
import { dateTimeFormater, isToday } from "../../utils/date_utils.js";
import { postReqOptBuilder, createDictFromHashedUrl } from "../../utils/main_utils";
//assets
import smallSearch from"../../assets/icons/search-small.svg"
import searchUp from "../../assets/icons/search-up.svg";
import searchDown from "../../assets/icons/search-down.svg";
import searchDownSelect from "../../assets/icons/search-down-select.svg";
import searchUpSelect from "../../assets/icons/search-up-select.svg";
import dots from "../../assets/icons/dots.svg";
//constants
const itemsPerPage = 20;

export default function Management (props) {
    const cid = props.uid_cid['cid'];
    const uid = props.uid_cid['uid'];
    // state based variables
    const [isLoader, setLoader] = useState(true);
    const [sortedUnits, setSortedUnits] = useState();
    const [currentSort, setCurrentSort] = useState({"column": null, "direction": null}); //sort direction(true ASC, false DESC)
    const [currentSearch, setCurrentSearch] = useState(null);
    const [currentSearchFilters, setCurrentSearchFilters] = useState({
        'upcomingCheckouts': {'opt': null},
        'pastReports': {'opt': null},
        'baseline-scan': {'opt': null},
        'unit-occupation': {'opt': null},
        'selected_properties': [],
    });
    const [activePms, setActivePms] = useState(false)
    const [pmsSyncData, setPmsSyncDate] = useState(null)
    const [activeDD, setActiveDD] = useState(null);
    const [maxItems, setMaxItems] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMainEmptyState, setMainEmptyState] = useState(false);
    const [extendedTableItem, setExtendedTableItem] = useState(null);
    const [unitExtendedInfo, setUnitExtendedInfo] = useState(null);
    const [notifState, setNotifState] = useState(null);
    const [popupState, setPopupState] = useState(null);
    const [hashParams, setHashParams] = useState({});
    const [blockClosePopup, setBlockClosePopup] = useState(false);
    // reference based variables
    const unitToDelete = useRef();
    // general
    const location = useLocation();

    //load of initial data
    useEffect(() => {
        if (!document.body.onhashchange) document.body.onhashchange = handleHashUrl;
        if (window.location.hash !== "") handleHashUrl();
        let body = {
            "cid": cid,
            "upperLimit": itemsPerPage
        };
        //if prev location is last report, set body according to previous state
        const historyState = window.history.state;
        console.log(historyState);
        if (historyState != null && historyState.state != null && historyState.state['from'].startsWith('/report/')) {
            const state = historyState.state;
            //unpack saved state
            if (state.sortColumn) {
                body["sortColumn"] = state.sortColumn;
                body["sortDirection"] = state.sortDirection;
                setCurrentSort({"column": state.sortColumn, "direction": state.sortDirection});
            }
            if (state.search) {
                body['searchValue'] = state.search;
                setCurrentSearch(state.search);
                document.getElementById("search").value = state.search;
            }
            if (state.searchFilters) {
                body['filters'] = state.searchFilters;
                setCurrentSearchFilters(state.searchFilters)
            }
            if (state.page) {
                body['page'] = state.page;
                body["lowerLimit"] = (state.page - 1) * itemsPerPage;
                body["upperLimit"] = state.page * itemsPerPage;
                setCurrentPage(state.page);
            }
            window.history.replaceState(null,null,null);
        }
        fetchUnits(body);
        if (location.state && location.state.isAllReports) {
            //return allReports modal
            handleAllReports(location.state.pid, location.state.subject);
        }

        fetch(props.para_be + '/settings/get_pms_creds', {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            if (response.status === 200){
                if (response.result !== null && Object.keys(response.result).length > 0) {
                    setActivePms(true);
                    let _lastPmsSyncDate = null;
                    for (const [pms_client, pms_client_data] of Object.entries(response.result)) {
                        if (pms_client_data.last_sync) {
                            _lastPmsSyncDate = pms_client_data.last_sync;
                        }
                    }
                    setPmsSyncDate(_lastPmsSyncDate);
                }
            } else {
                setNotifState({text: "Failed to verify PMS.", type: "error"});
            }
            const hashString = window.location.hash;
            if (hashString) setHashParams(createDictFromHashedUrl(hashString));
        })
        .catch(error => {
            console.log(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //TODO: extract extended info about unit
        if (extendedTableItem !== null) {
            fetch(props.para_be + "/units/get_extended_info?pid=" + extendedTableItem, {credentials: "include"})
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    setUnitExtendedInfo(response.result);
                } else {
                    setUnitExtendedInfo({});
                }
            })
        } else {
            setUnitExtendedInfo(null);
        }
    }, [extendedTableItem])

    const handleHashUrl = () => {
        console.log("Handling hash change in url");
        const hashDict = createDictFromHashedUrl(window.location.hash);
        console.log("Hash:", hashDict);
        if (Object.keys(hashDict).length > 0) {
            console.log("Updating hash params");
            setHashParams(hashDict);
            setCurrentSearch(hashDict.subject);
            document.getElementById("search").value = hashDict.subject;
            handleChangePage(1, hashDict.subject);
        }
    };

    const handleSortUnits = (index) => {
        let sortColumn = [1, 2, 3].includes(index) ? index : null;
        let sortDirection = currentSort.column !== index ? false : !currentSort.direction;

        fetchUnits({"cid": cid, "sortColumn": sortColumn, "sortDirection": sortDirection, "searchValue": currentSearch, "upperLimit": itemsPerPage, "filters": currentSearchFilters});
        setCurrentPage(1);
        setCurrentSort({"column": index, "direction": sortDirection});
    };

    const handleSearch = () => {
        const searchValue = document.querySelector("#search").value;
        handleChangePage(1, searchValue === null ? "" : searchValue);
        setCurrentSearch(searchValue === null ? "" : searchValue);
    };

    const handleChangePage = (page, searchValue=null, fromInput=false) => {
        if(searchValue === null) {
            searchValue = currentSearch;
        }
        fetchUnits({"cid": cid, "lowerLimit": (page-1)*itemsPerPage, 'upperLimit': page * itemsPerPage, "searchValue": searchValue, "sortColumn": currentSort.column, "sortDirection": currentSort.direction, "filters": currentSearchFilters});
        setCurrentPage(page);
    };

    const fetchUnits = (body) => {
        setLoader(true);
        let q = "?";
        for (let i of Object.keys(body)) {
            if (body[i] != null) q += (i + "=" + (i === "filters" ? JSON.stringify(body[i]) : body[i]) + "&");
        }
        q = q.slice(0, -1);
        fetch(props.para_be + '/units/search' + q, {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                if (Array.isArray(response.result.data) && response.result.data.length > 0) {
                    setSortedUnits(response.result.data.slice(0));
                    if (isMainEmptyState) setMainEmptyState(false);
                } else {
                    setMainEmptyState(true);
                }
                if (response.result.maxRows !== null && response.result.maxRows !== maxItems) {
                    setMaxItems(response.result.maxRows);
                }
            } else {
                setNotifState({text: response.msg, type: "error"});
            }
            setLoader(false);
        })
        .catch (error => {
            setMainEmptyState(true);
            setNotifState({text: "Can't fetch data", type: "error"});
            setLoader(false);
        });
    };

    const handleConfirmDeleteUnit = () => {
        fetch(props.para_be + '/units/remove', postReqOptBuilder({"cid": cid, "pid": unitToDelete.current}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                if (sortedUnits?.length === 1) {
                    if (currentPage === 1) setMainEmptyState(true);
                    else handleChangePage(currentPage - 1);
                } else handleChangePage(currentPage);
                unitToDelete.current = null;
                setPopupState(null);
            } else {
                setNotifState({text: response.msg, type: "error"});
            }
        })
        .catch (error => {
            setNotifState({text: "Delete failed", type: "error"});
        })
    };

    const openConfirmDeleteUnitPopup = (event, pid) => {
        event.stopPropagation();
        unitToDelete.current = pid;
        setPopupState([<ConfirmPopup
                            headlineText = {"Please confirm that you want to delete this unit."}
                            mainText = {"You won't be able to recover it."}
                            confirmText = {"Delete"}
                            declineText = {"Cancel"}
                            confirmChoice = {handleConfirmDeleteUnit}
                            declineChoice = {handleCloseConfirmPopup}
                            closeFunc = {handleCloseConfirmPopup}/>,
                        {closeFunc: handleCloseConfirmPopup}])
        
    };

    const handleCloseConfirmPopup = (event) =>{
        if (event) event.stopPropagation();
        setPopupState(null);
    };

    const handleUpdateBaseScan = (pid) => { };

    const handleOpenUpdateReferencePopup = (event, pid, key, subject, refID, updatedItem, placeholderName, itemKey) => {
        if (event) event.stopPropagation();
        setPopupState([<UpdateUnitRef 
                            pid={pid}
                            key={key}
                            subject={subject}
                            refID={refID}
                            updatedItem={updatedItem}
                            placeholderName={placeholderName}
                            itemKey={itemKey}
                            closeFunc={handleCloseUpdateRefNumber}
                            setLoader={setLoader}
                            para_be={props.para_be}/>, 
                        {closeFunc: handleCloseUpdateRefNumber}])
    };

    const handleCloseUpdateRefNumber = (fetch=false) => {
        if (fetch) fetchUnits({"cid": cid, "lowerLimit": (currentPage-1)*itemsPerPage, 'upperLimit': currentPage * itemsPerPage, "sortColumn": currentSort.column, "sortDirection": currentSort.direction, "searchValue": currentSearch, "upperLimit": itemsPerPage, "filters": currentSearchFilters});
        setPopupState(null);
    };

    useEffect(() => {
        if (activeDD) {
            document.body.addEventListener('click' , closeDD);
            return () => {document.body.removeEventListener('click' , closeDD)};
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDD]);

    const handleDropDown = (event, t) => {
        event.stopPropagation();
        if (activeDD === t) closeDD();
        else {
            if (activeDD) closeDD();
            setActiveDD(t);
        }
    };

    useEffect(()=> {
        closeDD();
    }, [popupState])

    const closeDD = () => {
        if (activeDD) setActiveDD(null);
    };

    const handleOpenNewUnitPopup =() => {
        setPopupState([<AddNewUnit closeFunc={handleClosePopup} para_be={props.para_be}/>, {closeFunc: handleClosePopup}])
    };

    const handleOpenBulkUploadPopup =() => {
        setPopupState([<BulkUpload closeFunc={handleClosePopup} para_be={props.para_be}/>, {closeFunc: handleClosePopup, extraClasses: "custom-scrollbar custom-scrollbar-1"}])
    };

    const handleAllReports = (pid, subject) => {
        setPopupState([<AllReports
                            closeFunc={handleClosePopup}
                            para_be={props.para_be}
                            pid={pid}
                            subject={subject}
                            sortColumn={currentSort.column}
                            sortDirection={currentSort.direction}
                            search={currentSearch}
                            searchFilters={currentSearchFilters}
                            page={currentPage}/>, 
                        {extraClasses: "non-padded-popup", closeFunc: handleClosePopup}]);
        return false;
    };

    const handleAllTenancies = (pid, subject) => {
        setPopupState([<AllTenancies
                            para_be={props.para_be}
                            pid={pid}
                            subject={subject}
                            sortColumn={currentSort.column}
                            sortDirection={currentSort.direction}
                            search={currentSearch}
                            searchFilters={currentSearchFilters}
                            page={currentPage}/>, 
                        {extraClasses: "non-padded-popup", closeFunc: handleClosePopup}]);
        return false;
    };

    const handleOpenBaselineVideo = (target_pid, target_subject) => {
        setPopupState([<ViewBaseline closeFunc={handleClosePopup} para_be={props.para_be} pid={target_pid} subject={target_subject}/>, {closeFunc: handleClosePopup, extraClasses: "has-bsp-container"}]);
    };

    const handleOpenSearchFilterPopup = () => {
        setPopupState([
            <SearchFilter 
                closeFunc={handleClosePopup} 
                para_be={props.para_be} 
                activePMS={activePms} 
                currentFilters={currentSearchFilters} 
                applyChanges={ (newFilters) => { setCurrentSearchFilters(newFilters); }} 
            />, 
            {extraClasses: "non-padded-popup", closeFunc: handleClosePopup}
        ]);
    }

    const handleClosePopup = (refresh=false) => {
        if (!blockClosePopup) {
            setPopupState(null);
            if (refresh) handleChangePage(currentPage);
        }
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    const handleSyncPMS = () => {
        fetch(props.para_be + '/pms/sync_data', {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setNotifState({"type": "success", "text": "Sync complete"});
            } else {
                setNotifState({"type": "error", "text": response.msg});
            }
        })
        .catch (error => {
            console.log(error);
            setNotifState({"type": "error", "text": "An error has occurred, please try again later"});
        })
    }
    
    const updateURL = (urlData) => {
        //update the current workspace data
        let tempData = sortedUnits.slice(0);
        for (let i=0; i<tempData.length; i++) {
            if (tempData[i][0] === urlData.pid) {
                tempData[i][6] = true;
                tempData[i][7] = urlData.url;
                setSortedUnits(tempData);
                break;
            }
        }
    };
    
    // Handles the initiation of video upload process and sets the popup state
    const handleUploadVideo = (pid) => {
        setLoader(false)
        setPopupState([<VideoUploader pid={pid} changeBlockStatus={changeBlockStatus} closeFunc={handleClosePopup} setNotifState={setNotifState} para_be={props.para_be} />, {closeFunc: handleClosePopup, extraClasses: "has-bsp-container"}]);
    }

    const changeBlockStatus = (isBlocked) => {
        setBlockClosePopup(isBlocked);
    }

    return (
        <section className="mgmt-main">
            <Helmet>
                <title>Paraspot | Management</title>
                <meta name="description" content="."/>
                <meta property="og:title" content="Paraspot | Management"/>
                <meta property="og:description" content="."/>
            </Helmet>
            <div className="mgmt-header flexRow">
                <div className="mgmt-search flexRow">
                    <input className="text-1" id="search" placeholder="Search" onKeyUp={() => handleSearch()}/>
                    <img className="unselectable" src={smallSearch} alt="small-search"/>
                </div>
                <div className ="mgmt-sort mobile-only">
                    <SimpleInputDropdown
                        placeholder="Sort by"
                        keepPlaceholder={true}
                        selectorClasses="pad-5-ud"
                        borderedItems={true}
                        onclick={(item) => handleSortUnits(item === 'reference' ? 1 : item === 'unit' ? 2 : 3)}
                        items={[['reference', 'Reference'], ['unit', 'Unit'], ['last_report', 'Last Report']].reduce((dict, [k, v], idx) => {
                            let isCurrentSort = currentSort.column === idx+1;
                            return {[k]: {'present': v, 'disabled': false, 'preItemText': 
                                            <div className="sort-icons flexColumn">
                                                <img src={(isCurrentSort && currentSort.direction === true) ? searchUpSelect : searchUp} alt="arrow-up"/>
                                                <img src={(isCurrentSort && currentSort.direction === false) ? searchDownSelect : searchDown} alt="arrow-down"/>
                                            </div>}, ...dict}
                        }, {})}/>
                </div>
                <div className="mgmt-header-btns flexRow">
                    <Btn 
                        text="Filter"
                        type="secondary"
                        onclick={() => handleOpenSearchFilterPopup()}/>
                    <Btn 
                        text="Sync PMS"
                        type="primary"
                        btnDisabled={!activePms}
                        onclick={() => activePms ? handleSyncPMS() : true}/>
                    <Btn 
                        text="Bulk Upload"
                        type="primary"
                        onclick={() => handleOpenBulkUploadPopup()}/>
                    <Btn 
                        text="Add New Unit"
                        type="primary"
                        extraClasses="mgmt-add"
                        onclick={() => handleOpenNewUnitPopup()}/>
                </div>
            </div>

            <div className="mgmt-table">
                <div className="top-pg-container">
                    <div>{currentPage < 2 ? 1 : (((currentPage-1)*itemsPerPage) + 1)}-{itemsPerPage*currentPage >= maxItems ? maxItems : itemsPerPage*currentPage} of {maxItems} Units&nbsp;&nbsp;|&nbsp;&nbsp;Last Sync: {pmsSyncData}{pmsSyncData ? " UTC": ""}</div>
                </div>
                <table cellSpacing="0" cellPadding="0">
                    <thead className="hide-mobile">
                        <tr>
                            {Object.entries({'Reference': {'sortable': true, 'className': 'th-ref'}, 'Unit': {'sortable': true, 'className': 'th-subject'}, 'Status': {'sortable': true, 'className': 'th-ls'}, 'Share': {'sortable': false, 'className': 'th-share'}, 'Action': {'sortable': false, 'className': 'th-action'}}).map(([k, v], index) => {
                                let isCurrentSort = currentSort.column === index+1 && v['sortable'];
                                return (
                                    <th className={v['className'] + (isCurrentSort ? " sort-active" : "")}>
                                        <div {...(v['sortable'] ? {onClick: () => handleSortUnits(index+1)} : {})}>
                                            <span>{k}</span>
                                            {v['sortable'] &&
                                                <div className="sort-icons flexColumn">
                                                    <img src={isCurrentSort && currentSort.direction === true ? searchUpSelect : searchUp} alt="arrow-up"/>
                                                    <img src={isCurrentSort && currentSort.direction === false ? searchDownSelect : searchDown} alt="arrow-down"/>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                )})
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader ? <MainLoader/> :
                            isMainEmptyState ? <EmptyState text={"No data, add new item"}  size="lg"/> :
                            sortedUnits?.map(function (item, index) {
                                //data: 0-PID, 1-ext_ref, 2-subject, 3-report status, 4-report date, 5-report id, 6-baseline-status, 7-scan url, 8-is external reference
                                let rowStatus, format, dateTimeToPrint;
                                if(item[4]) {
                                    rowStatus = ((item[3] !== 1 && item[3] !== 0) ? null : (item[3] === 1 ? "Pass" : "Fail"));
                                    format = isToday(item[4]) ? "HH:MM" : "dd/mm/yyyy";
                                    dateTimeToPrint = item[4] ? dateTimeFormater(item[4], format) : "";
                                }

                                const linkParams = {
                                    pathname: "report/" + item[5],
                                    state: {"pid": item[0], 
                                            "reportId": item[5],
                                            "subject": item[2],
                                            "sortColumn": currentSort.column,
                                            "sortDirection": currentSort.direction,
                                            "search": currentSearch,
                                            "filters": currentSearchFilters,
                                            "page": currentPage,
                                            "isAllReports": false}
                                };
                                const item_id = "mgmt_item_" + index;
                                const extendedSection = item[0] !== extendedTableItem ? "" : unitExtendedInfo === null ? 
                                    <tr className="table-row-extension">
                                        <td colSpan={6}>
                                            <MainLoader/> 
                                        </td>
                                    </tr> : Object.keys(unitExtendedInfo).length === 0 ? 
                                    <tr className="table-row-extension">
                                        <td colSpan={6}>
                                            <div className="loo-placeholder"></div>
                                            <EmptyState text={"There is no extended information to present on the unit"}  size="lg"/>
                                        </td>
                                    </tr>
                                    :
                                    <tr className="table-row-extension">
                                        <td colSpan={6}>
                                            <div className="unit-labels flexRow">
                                                {(unitExtendedInfo['labels'] || []).map( (ext_label_item) => <span className="para-label ls-status para-l-info">{ext_label_item}</span> )}
                                            </div>

                                            <div className="unit-info">
                                                <div className="text-1-3">Current Guest/Tenant</div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Name: {unitExtendedInfo['name'] || "NaN"}
                                                    </div>
                                                </div>
                                                
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Email: {unitExtendedInfo['email'] || "NaN"}
                                                    </div>
                                                </div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Mobile: {unitExtendedInfo['mobile'] || "NaN"}
                                                    </div>
                                                </div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Check-In Date: {unitExtendedInfo['check-in'] || "NaN"}
                                                    </div>
                                                </div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Checkout Date: {unitExtendedInfo['checkout'] || "NaN"}
                                                    </div>
                                                </div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Security Deposit: {unitExtendedInfo['deposit'] || "NaN"}
                                                    </div>
                                                </div>
                                                <div className="unit-info-item flexRow">
                                                    <div className="arrow-right"></div>
                                                    <div className="text-2">
                                                        Notice: {unitExtendedInfo['notice_info'] || "NaN"}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>;

                                return (
                                    <>
                                        <tr id={item_id} key={item_id} onClick={() => { 
                                                console.log("[i] Changing table item extension view to:", item[0] !== extendedTableItem ? item[0] : null); 
                                                setExtendedTableItem(item[0] !== extendedTableItem ? item[0] : null); 
                                            }}>
                                            <td className={currentSort.column === 1 ? "sort-active" : ""} data-th="Reference">{item[1]}</td>
                                            <td className={currentSort.column === 2 ? "sort-active" : ""} data-th="Unit" title={item[2]}>{item[2]}</td>
                                            <td className={currentSort.column === 3 ? "sort-active" : ""} data-th="Last Report">
                                                {/* {rowStatus == null ? "" : 
                                                    <span className={"para-label ls-status para-l-" + rowStatus.toLowerCase()}>{rowStatus}</span>
                                                } */}
                                                {/* <div>
                                                    <span>{item[4] === null ? "" : dateTimeToPrint}</span>
                                                </div> */}
                                                <div className="flexRow">
                                                    { item[4] !== null &&
                                                        <div className="miss-baseline-label para-label para-l-pass">{dateTimeToPrint}</div>
                                                    }
                                                    { item[6] ? "" : !item[9] ? 
                                                        <div className="miss-baseline-label para-label para-l-warn">Missing baseline</div> : 
                                                        <div className="miss-baseline-label para-label para-l-info">Building baseline</div>
                                                    }
                                                </div>
                                            </td>
                                            <td data-th ="Share">
                                                <UrlGeneratorCell
                                                    pid={item[0]}
                                                    updateURL={updateURL}
                                                    cid={cid}
                                                    para_be={props.para_be}
                                                    scan_link={item[7]}
                                                    showDropdown={activeDD === `${item[0]}#copy-opt`}
                                                    handleDropDown={handleDropDown}
                                                    updateNotifState={setNotifState} />
                                                {/* {item[6] ?
                                                    <UrlGeneratorCell
                                                        pid={item[0]}
                                                        updateURL={updateURL}
                                                        cid={cid}
                                                        para_be={props.para_be}
                                                        scan_link={item[7]}
                                                        showDropdown={activeDD === `${item[0]}#copy-opt`}
                                                        handleDropDown={handleDropDown}
                                                        updateNotifState={setNotifState}/> : 
                                                    !item[9] ? 
                                                        <div className="miss-baseline-label para-label para-l-warn">Missing baseline</div> : 
                                                        <div className="miss-baseline-label para-label para-l-info">Building baseline</div>
                                                } */}
                                            </td>
                                            <td>
                                                <div className="report-btns">
                                                    {item[6] || item[5] ?
                                                        <>
                                                            <Link to={item[4] ? linkParams : ""} className={"simple-btn hide-tablet" + (item[4] ? "" : " disable-click")} {...(item[4] ? {} : {onClick: 'return false;'})}>Last Report</Link>
                                                            <div className="simple-btn hide-tablet" onClick={() => handleAllReports(item[0], item[2])}>All Reports</div>
                                                        </> :
                                                        <Link to={"/" + item[0] + "/baseline-scan"} className={"simple-btn hide-tablet"}>Create Baseline</Link>
                                                    }
                                                    <SimpleDropdownContainer
                                                        extraClasses="more-items"
                                                        showDropdown={activeDD === item[0]}
                                                        borderedItems={true}
                                                        items={{
                                                            'all_tenancies': {'present': 'All Tenancies', 'disabled': false, 'onclick': (k, e) => handleAllTenancies(item[0], item[2])},
                                                            ...(item[6] || item[5] ? {
                                                                'show_baseline': {'present': 'Show Baseline Scan', 'disabled': false, 'onclick': (k, e) => handleOpenBaselineVideo(item[0], item[2])},
                                                                'last_report': {'disabled': !item[4], 'extraClasses': 'tablet-only', 'content': <Link to={item[4] ? linkParams : ""} {...(item[4] ? {} : {onClick: 'return false;'})}>Last Report</Link>, 'onclick': (k, e) => true},
                                                                'all_reports': {'present': 'All Reports', 'extraClasses': 'tablet-only', 'disabled': false, 'onclick': (k, e) => handleAllReports(item[0], item[2])},
                                                            } : {}),
                                                            ...(item[6] ? {
                                                                'update_baseline': {'disabled': false, 'content': <Link to={"/" + item[0] + "/baseline-scan"}>Update Baseline Scan</Link>}
                                                            } : {'create_baseline': {'extraClasses': 'tablet-only', 'disabled': false, 'content': <Link to={"/" + item[0] + "/baseline-scan"}>Create Baseline</Link>}}),
                                                            ...(cid === "210623105234tzVCiQFgqehrDQ0T" ? {'view_inventory': {'content': <Link to={"/" + item[0] + "/unit-inventory"}>View Unit Inventory</Link>}} : {}),
                                                            'upload_baseline': {'present': 'Upload baseline', 'disabled': false, 'onclick': () => handleUploadVideo(item[0])},
                                                            'edit_unit': {'present': 'Edit Unit Address', 'disabled': item[8], 'onclick': (k, e) => handleOpenUpdateReferencePopup(e, item[0], item_id, item[2], item[1], "Unit Address", "unit address", "address")},
                                                            'edit_ref': {'present': 'Edit Reference Number', 'disabled': item[8], 'onclick': (k, e) => handleOpenUpdateReferencePopup(e, item[0], item_id, item[2], item[1], "Reference ID", "reference ID", "extRef")},
                                                            'remove_unit': {'present': 'Remove Unit', 'disabled': item[8], 'onclick': (k, e) => openConfirmDeleteUnitPopup(e, item[0])},
                                                        }}>
                                                        <img src={dots} alt="more-btn" onClick={(e) => handleDropDown(e, item[0])}/>
                                                    </SimpleDropdownContainer>
                                                </div>
                                            </td>
                                        </tr>
                                        {extendedSection}
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
                {isMainEmptyState ? "" :
                    <Pagination
                        currentPage = {currentPage}
                        maxPages = {Math.ceil(maxItems / itemsPerPage)}
                        handleChangePage = {(page) => handleChangePage(page)}
                        setNotifState = {setNotifState}
                        itemsPerPage = {itemsPerPage}
                    />
                }
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
            {popupState ? <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup> : ""}
        </section>
    )
}