import React, {useState} from "react";
//components
import CloseBtn from "../../buttons/close_btn/close_btn";
import BackBtn from "../../buttons/back_btn/back";
import Notification from "../../side_notification/side_notification";
import Btn from "../../buttons/standard/btn";
//styles
import "./update_unit_ref.css";
import { postReqOptBuilder } from "../../../utils/main_utils";
import SimpleInput from "../../inputs/simple_input/input";


export default function UpdateUnitRef (props) {
    console.log(props);
    const [notifState, setNotifState] = useState(false);
    const [inputError, setInputError] = useState(null);

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    const handleAssignReferenceNumber = (event) => {
        event.preventDefault();
        setInputError(null);
        let body = {"pid": props.pid};
        let newValue = document.querySelector(".update-ref-input input")?.value;
        if (!newValue || newValue.length === 0) {
            setInputError("Please enter " + props.placeholderName);
            return;
        }
        body[props.itemKey] = newValue;
        
        fetch(props.para_be + '/units/update_unit', postReqOptBuilder(body))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                props.setLoader(true);
                props.closeFunc(true);
            } else {
                setNotifState({text: response.msg, type: "error"});
            }
        })
        .catch (error => {
            setNotifState({text: "Update failed", type: "error"});
        });
    };

    return (
        <div className="update-ref">
            {/* <CloseBtn closeFunc = {props.closeFunc}/>
            <BackBtn clickFunction = {props.closeFunc}/> */}
            <div className="update-ref-header h2 blue-headline">Edit {props.updatedItem}</div>
            <div className="update-ref-subject text-1"><span>Unit</span> {props.subject}</div>
            <div className="update-ref-subject text-1"><span>Reference</span> {props.refID}</div>
            <div className="update-ref-input">
                <SimpleInput
                    label={"New " + props.updatedItem}
                    placeholder={`Enter your new ${props.placeholderName ? props.placeholderName : props.updatedItem.toLowerCase()}`}
                    inputError={inputError}
                />
            </div>
            <Btn
                text = {`Update ${props.updatedItem}`}
                type ="primary"
                onclick = {(e)=>handleAssignReferenceNumber(e)}
            />
   
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div>
    )
}
